import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Badge, Tooltip } from 'antd';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import JobSectionList from '@components/JobSectionList/JobSectionList';
import { CenteredLoader } from '@components/onboarding/form/steps/styles';
import RadioButton from '@components/RadioButton/RadioButton';
import { SearchBar } from '@components/SearchBar';
import { getMainLayout } from '@layouts/MainLayout';
import { BookingsActionQueryApi } from '@lib/api/endpoints/bookings/query';
import { FirstAvailActionQueryApi } from '@lib/api/endpoints/firstAvail/query';
import { InvitesQueryApi } from '@lib/api/endpoints/invites';
import { InvitesActionQueryApi } from '@lib/api/endpoints/invites/query';
import { RootState } from '@lib/redux.lib/store';

import { getFormattedDates } from '../../../lib/utils/jobsHelper';
import CameraVideoIcon from '../../../svg/CameraVideo.svg';
import { NextPageWithLayout } from '../../_app';

const InviteIndex: NextPageWithLayout = () => {
  const [invite, setInvite] = useState<any[]>(null);
  const [inviteAction, setInviteAction] = useState<any[]>(null);
  const [inviteArchived, setInviteArchived] = useState<any[]>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const userId = useSelector((state: RootState) => state.app.user?.profile?.id);

  const router = useRouter();
  const isMobileDevice = window.innerWidth <= 700;
  const isSmallMobileDevice = window.innerWidth == 414;

  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const handleSearchInputChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { data: countInvitesRes } = useQuery({
    queryKey: ['countInvites'],
    queryFn: () => {
      if (!userId) return;
      return InvitesActionQueryApi.getUserInvitesActionType(userId);
    },
  });
  const countInvites = countInvitesRes?.submissions_count;

  const { data: countAvailChecksRes } = useQuery({
    queryKey: ['countAvailChecks'],
    queryFn: () => {
      if (!userId) return;
      return FirstAvailActionQueryApi.getUserFirstAvailActionType(userId);
    },
  });
  const countAvailChecks = countAvailChecksRes?.first_avail_count;

  const { data: countBookingsRes } = useQuery({
    queryKey: ['countBookings'],
    queryFn: () => {
      if (!userId) return;
      return BookingsActionQueryApi.getUserBookingsActionTypes(userId);
    },
  });
  const countBookings = countBookingsRes?.booked_count;

  // initializing the data
  useEffect(() => {
    const dataLoad = [
      InvitesQueryApi.getUserInvites(userId)
        .then((InvitesData) => {
          setInvite(InvitesData);
        })
        .finally(() => {
          setIsLoading(false);
        }),
      InvitesActionQueryApi.getUserInvitesAction(userId).then(
        (InvitesAction) => {
          setInviteAction(InvitesAction);
        }
      ),
      InvitesActionQueryApi.getUserInvitesArchived(userId).then(
        (InvitesArchived) => {
          setInviteArchived(InvitesArchived);
        }
      ),
    ];
    // we use Promise.all instead of await so the requests can be made in parallel
    Promise.all(dataLoad)
      .catch((error) => {
        console.error('error while api:', error);
      })
      .finally(() => {
        console.debug('LOADING COMPLETED');
      });
  }, []);

  const filteredData =
    invite?.filter((row) =>
      row.project_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const filteredDataAction =
    inviteAction?.filter((row) =>
      row.project_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const filteredDataArchived =
    inviteArchived?.filter((row) =>
      row.project_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const dataArchived =
    filteredDataArchived?.map((row, id) => {
      const dates = [];
      for (const key in row.related_dates) {
        if (Array.isArray(row.related_dates[key])) {
          dates.push(...row.related_dates[key]);
        }
      }
      const formattedDates = getFormattedDates(dates);
      const plusDays =
        formattedDates.length > 3 ? `+${formattedDates.length - 3}` : '';
      return {
        list_membership_id: row.list_membership_id,
        projectname: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {!isMobileDevice && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  background: '#F2F2F2',
                  marginRight: '8px',
                }}
              >
                <img
                  src={CameraVideoIcon.src}
                  alt='CameraVideo Icon'
                  style={{ height: '18px', width: '18px' }}
                />
              </div>
            )}
            {row.project_name}
          </span>
        ),
        role: row.related_column_values.Role,
        location: `${row.formatted_project_location?.city}, ${row.formatted_project_location?.state}`,
        dates: (
          <span>
            {formattedDates.length > 3 ? (
              <Tooltip
                overlayInnerStyle={{
                  backgroundColor: '#4F4F4F',
                  color: '#F9F9F9',
                  padding: 16,
                  fontSize: 12,
                }}
                showArrow={false}
                title={formattedDates.join('')}
              >
                <span style={{ cursor: 'pointer' }}>
                  {formattedDates.slice(0, 3)} <span>{plusDays}</span>
                </span>
              </Tooltip>
            ) : (
              <span>
                {formattedDates.slice(0, 3)} <span>{plusDays}</span>
              </span>
            )}
          </span>
        ),
        status: [
          row.list_status === 'invited'
            ? 'Invited'
            : row.list_status === 'submitted'
            ? 'Available'
            : row.list_status === 'unavailable'
            ? 'Unavailable'
            : row.list_status === 'misc_message_list'
            ? 'Misc Message'
            : '',
        ],
        tags: [
          row.list_status === 'invited'
            ? 'Invited'
            : row.list_status === 'submitted'
            ? 'Available'
            : row.list_status === 'unavailable'
            ? 'Unavailable'
            : row.list_status === 'misc_message_list'
            ? 'Misc Message'
            : '',
        ],
        notification_id: row.notification_id ? row.notification_id : '',
      };
    }) || [];

  const countLengthArchived = dataArchived.length;
  const data = filteredData.map((row, id) => {
    const dates = [];
    for (const key in row.related_dates) {
      if (Array.isArray(row.related_dates[key])) {
        dates.push(...row.related_dates[key]);
      }
    }
    const formattedDates = getFormattedDates(dates);
    const plusDays =
      formattedDates.length > 3 ? `+${formattedDates.length - 3}` : '';

    return {
      list_membership_id: row.list_membership_id,
      projectname: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!isMobileDevice && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                background: '#F2F2F2',
                marginRight: '8px',
              }}
            >
              <img
                src={CameraVideoIcon.src}
                alt='CameraVideo Icon'
                style={{ height: '18px', width: '18px' }}
              />
            </div>
          )}
          {row.project_name}
        </span>
      ),
      role: row.related_column_values.Role,
      location: `${row.formatted_project_location?.city}, ${row.formatted_project_location?.state}`,
      dates: (
        <span>
          {formattedDates.length > 3 ? (
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: '#4F4F4F',
                color: '#F9F9F9',
                padding: 16,
                fontSize: 12,
              }}
              showArrow={false}
              title={formattedDates.join('')}
            >
              <span style={{ cursor: 'pointer' }}>
                {formattedDates.slice(0, 3)} <span>{plusDays}</span>
              </span>
            </Tooltip>
          ) : (
            <span>
              {formattedDates.slice(0, 3)} <span>{plusDays}</span>
            </span>
          )}
        </span>
      ),
      status: [
        row.list_status === 'invited'
          ? 'Invited'
          : row.list_status === 'submitted'
          ? 'Available'
          : row.list_status === 'unavailable'
          ? 'Unavailable'
          : row.list_status === 'misc_message_list'
          ? 'Misc Message'
          : '',
      ],
      tags: [
        row.list_status === 'invited'
          ? 'Invited'
          : row.list_status === 'submitted'
          ? 'Available'
          : row.list_status === 'unavailable'
          ? 'Unavailable'
          : row.list_status === 'misc_message_list'
          ? 'Misc Message'
          : '',
      ],
      notification_id: row.notification_id ? row.notification_id : '',
    };
  });
  const countLength = data.length;

  const dataAction = filteredDataAction.map((row, id) => {
    const dates = [];
    for (const key in row.related_dates) {
      if (Array.isArray(row.related_dates[key])) {
        dates.push(...row.related_dates[key]);
      }
    }
    const formattedDates = getFormattedDates(dates);
    const plusDays =
      formattedDates.length > 3 ? `+${formattedDates.length - 3}` : '';

    return {
      list_membership_id: row.list_membership_id,
      projectname: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!isMobileDevice && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                background: '#F2F2F2',
                marginRight: '8px',
              }}
            >
              <img
                src={CameraVideoIcon.src}
                alt='CameraVideo Icon'
                style={{ height: '18px', width: '18px' }}
              />
            </div>
          )}
          {row.project_name}
        </span>
      ),
      role: row.related_column_values.Role,
      location: `${row.formatted_project_location?.city}, ${row.formatted_project_location?.state}`,
      dates: (
        <span>
          {formattedDates.length > 3 ? (
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: '#4F4F4F',
                color: '#F9F9F9',
                padding: 16,
                fontSize: 12,
              }}
              showArrow={false}
              title={formattedDates.join('')}
            >
              <span style={{ cursor: 'pointer' }}>
                {formattedDates.slice(0, 3)} <span>{plusDays}</span>
              </span>
            </Tooltip>
          ) : (
            <span>
              {formattedDates.slice(0, 3)} <span>{plusDays}</span>
            </span>
          )}
        </span>
      ),
      status: [
        row.list_status === 'invited'
          ? 'Invited'
          : row.list_status === 'submitted'
          ? 'Available'
          : row.list_status === 'unavailable'
          ? 'Unavailable'
          : row.list_status === 'misc_message_list'
          ? 'Misc Message'
          : '',
      ],
      tags: [
        row.list_status === 'invited'
          ? 'Invited'
          : row.list_status === 'submitted'
          ? 'Available'
          : row.list_status === 'unavailable'
          ? 'Unavailable'
          : row.list_status === 'misc_message_list'
          ? 'Misc Message'
          : '',
      ],
      notification_id: row.notification_id ? row.notification_id : '',
    };
  });

  const handleItemClick = (item) => {
    if (item.status[0] === 'Misc Message')
      router.push(
        `/jobs/Invites/Invited?list_membership_id=${item.list_membership_id}&notification_id=${item.notification_id}`
      );
    else
      router.push(
        `/jobs/Invites/Invited?list_membership_id=${item.list_membership_id}`
      );
  };

  return (
    <>
      <ContentWrapper style={{ width: isSmallMobileDevice ? '414px' : '100%' }}>
        <HeadingContainer>
          <Heading1>Invites</Heading1>
        </HeadingContainer>
        <RadioButton
          status='invite'
          badgeCountInvites={<CustomBadge count={countInvites} />}
          badgeCountAvailChecks={<CustomBadge count={countAvailChecks} />}
          badgeCountBookings={<CustomBadge count={countBookings} />}
        />
        <div style={{ marginTop: '20px' }}>
          <StyleSearch
            placeholder='Search'
            onChange={handleSearchInputChange}
            value={searchQuery}
          />
        </div>
        {isLoading ? null : ( // Check if loading // Display loading message
          <>
            {countInvites > 0 && (
              <JobSectionList
                defaultActiveKey={['1']}
                onClick={handleItemClick}
                sectionTitle={'Action Required'}
                badgeCount={<CustomBadge count={countInvites} />}
                data={dataAction}
              />
            )}
            {countLength > 0 && (
              <JobSectionList
                defaultActiveKey={['1']}
                onClick={handleItemClick} // Pass the onClick handler function
                sectionTitle={'Invites'}
                badgeCount={countLength}
                data={data}
              />
            )}
            {countLengthArchived > 0 && (
              <JobSectionList
                onClick={handleItemClick} // Pass the onClick handler function
                sectionTitle={'Archived'}
                badgeCount={countLengthArchived}
                data={dataArchived}
              />
            )}
            {countInvites === 0 &&
              countLength === 0 &&
              countLengthArchived === 0 && (
                <StyledEmptyText>
                  You do not have any invites yet. When you get invited to or
                  submit to a role, it will appear on this page.
                </StyledEmptyText>
              )}
          </>
        )}
      </ContentWrapper>
    </>
  );
};

InviteIndex.getLayout = getMainLayout;
export default InviteIndex;

const ContentWrapper = styled.div`
  padding: 24px;
  margin: 0;
  min-height: 550px;
  width: '100%';
  @media (max-width: 576px) {
    background: #f9f9f9;
    padding: 0px;
    min-height: 920px;
  }
`;

const CustomBadge = styled(Badge)`
  .ant-badge-count {
    background: #e14a4a;
    box-shadow: none;
    width: 100%;
    font-weight: 700;
    font-size: 11px;
  }
`;

const Heading1 = styled.h1`
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 17px;
  }
`;

const StyleSearch = styled(SearchBar)`
  width: 380px;
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */
  color: #202020;
  @media (max-width: 767px) {
    display: none;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    margin-left: 15px;
  }
`;

const StyledEmptyText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-top: 2%;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;
